<template>
  <div id="modal" class="modal modal-backdrop" style="display: block; overflow: hidden;" aria-modal="true" role="dialog">
    <!-- <div class="modal"> -->
      

      <!-- <section class="modal-body"> -->
    <nav class="fh5co-nav" role="navigation">
        <div class="container">
            <div class="row">
            <div class="col-xs-12 text-center">
                <div id="fh5co-logo"><a href="" style="font-family: 'Oleo Script', Sans-serif;">Wedding Invitation<strong>.</strong></a></div>
            </div>
            </div>
        </div>
    </nav>
    <!-- <header id="fh5co-header" class="fh5co-cover img-fluid mb-5" alt="Responsive image" role="banner" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')', width: 'auto', height : 'auto'}" data-stellar-background-ratio="0.5"> -->
    <header id="fh5co-header" class="fh5co-cover img-fluid mb-5" alt="Responsive image" role="banner" style="background-image:url(../assets/images/image1-up.jpg); background-position: center;"  >    
        <div class="overlay"></div>
        <div class="container">
            <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center">
                <div class="display-t">
                <div class="display-tc animate-box" data-animate-effect="fadeIn">
                    <h1 v-if="client.hajat == 'wanita'">{{client.nama_wanita}} &amp; {{client.nama_pria}} </h1>
                    <h1 v-if="client.hajat == 'pria'">{{client.nama_pria}} &amp; {{client.nama_wanita}} </h1>
                    <h2 v-if="config.language ===  0">Kepada Bapak/Ibu/Saudara/i</h2>
                    <h2 v-if="config.language ===  1">To Mr./Mrs./Sir/Madam</h2>
                    <h3 style="font-weight: 600;">{{guest.nama}}</h3>
                    <h3 style="font-weight: 600;" v-if="showName">{{name}}</h3>
                    <h6 v-if="config.language ===  0">Tanpa mengurangi rasa hormat, Kami mengundang Anda untuk hadir diacara pernikahan Kami</h6>
                    <h6 v-if="config.language ===  1">Without reducing respect, we invite you to attend our wedding</h6>
                    <!-- <p><a href="#" class="btn btn-outline-default btn-sm">Buka Undangan</a></p> -->
                    <!-- <router-link class="btn btn-outline-default btn-sm" :to="{ name: 'Home', params: {url: client.slug}, query: {to:guest.url}}">
                        Buka Undangan
                    </router-link> -->
                    <button
                    class="btn btn-outline-default btn-sm"
                    @click="close"
                    v-if="config.language ===  0"
                    >
                    Buka Undangan
                    </button>
                    <button
                    class="btn btn-outline-default btn-sm"
                    @click="close"
                    v-if="config.language ===  1"
                    >
                    Open Invitation
                    </button>
                </div>
                </div>
            </div>
            </div>
        </div>
    </header>    <!-- <slot name="body"> -->
         
        <!-- </slot> -->
       <!-- </section> -->
    <!-- </div> -->
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Modal',
      props: ['client', 'guest', 'config'],
      methods: {
        close() {
          this.$emit('close');
        },
    },
    computed: {
        name() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                if (toParam.includes('-')) {
                return '';
                } else {
                return toParam;
                }
            } else {
                return '';
            }
        },
        showName() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                return !toParam.includes('-');
            } else {
                return false;
            }
        },
    },
  };
</script>

<style>
  .modal-backdrop {
    background: #FFFFFF;
  }
</style>