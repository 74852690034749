<template>
    <div id="fh5co-gallery" class="fh5co-section-gray">
      <img class="corner-top-left" src="assets/images/ornamentfoto1.svg" alt="" style="padding: 0.5rem;">
      <img class="corner-top-right" src="assets/images/ornamentfoto1.svg" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-left" src="assets/images/ornamentfoto1.svg" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-right" src="assets/images/ornamentfoto1.svg" alt="" style="padding: 0.5rem;">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <span>Our Memories</span>
            <h2 style="font-family: 'Oleo Script', Sans-serif;">Galeri Foto</h2>
          </div>
        </div>
        <div class="row row-bottom-padded-md" style="padding-top: 10px;">
            <!-- <div class="col-md-12">
                <split-carousel :item-width="245" :height="350" :item-align="center">
                    <split-carousel-item  v-for="item in gallery" :key="item.id">
                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file" class="img-thumbnail" alt="">
                    </split-carousel-item>
                </split-carousel>
            </div> -->
            <!-- <div class="row justify-content-center animate-box">
                <div v-for="item in gallery" :key="item.id" class="col-lg-4 col-md-8 col-sm-10">
                    <div class="text-center" style="margin-bottom: 50px;" >
                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file" style="border-radius: 5px; height: 350px; width:90%;" class="img-fluid img-thumbnail" alt="...">
                    </div>
                </div>
            </div> -->
            <div class="col-md-12 animate-box">
                <vue-horizontal ref="horizontal" class="horizontal" :button-between="false" @scroll-debounce="onScrollDebounce">
                    <div class="item" v-for="(item, index) in gallery" :key="index">
                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file"  class="gallery img d-flex align-items-center justify-content-center" alt="...">
                    </div>
                </vue-horizontal>
                <div class="pagination">
                    <div class="dot" :class="{ current: i - 1 === index }" v-for="i in pages" :key="i" >
                        <div></div>
                    </div>
                </div>
            </div>
        </div>  
      </div>
    </div>
</template>

<script>
import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel";
import VueHorizontal from "vue-horizontal";
export default {
    props: ['client', 'gallery'],
    components: {
      SplitCarousel,
      SplitCarouselItem,
      VueHorizontal
    },
    data(){
      return {
        width: 0,
        index: 0,
        pages: 0
      }
    },
    mounted() {
      this.pages = Math.ceil(this.gallery.length / this.width);
    },
    methods:{
      onScrollDebounce({scrollWidth, width, left}) {
          this.width = width
          this.index = Math.round(left / width)
          this.pages = Math.round(scrollWidth / width)
      },
      onPageClick(i) {
          if (i === this.pages - 1) {
              this.$refs.horizontal.scrollToIndex(this.gallery.length - 1)
          } else {
              this.$refs.horizontal.scrollToLeft(i * this.width)
          }
      },
    },
    watch: {
      gallery() {
        this.pages = this.gallery.length;
      }
    }
}
</script>


<style scoped>
.item {
  width: 240px;
  /* padding: 32px 32px 24px 32px;
  background: #f1f1f1; */
  margin-right: 20px;
  display: flex;
  align-items: center; /* added */
}

@media screen and (max-width: 768px) {
  .item {
    width: 280px;
  }
}

.content {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  padding-top: 60%;
  height: 240px;
}

.title {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
</style>

<!-- Pagination CSS -->
<style scoped>
.pagination {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.dot {
  padding: 4px;
  cursor: pointer;
}

.dot > div {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background: #33333350;
}

.dot:hover > div {
  border: 1px solid black;
  background: white;
}

.dot.current > div {
  border: 3px solid black;
  background: white;
}
</style>

<!-- Parent CSS (Container) -->
<style scoped>
main, article {
  padding: 24px 0;
}
</style>