<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <!-- <header id="fh5co-header" class="fh5co-cover" role="banner" v-bind:style="{ 'background-image': 'url( https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover + ')' }" data-stellar-background-ratio="0.5"> -->
    
    <header id="fh5co-header" class="fh5co-cover" role="banner" style="background-image:url(../assets/images/image1-up.jpg); background-position: center;" >
        <div class="overlay"></div>
            <div class="container">
            <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center">
                <div class="display-t">
                <div class="display-tc animate-box" data-animate-effect="fadeIn">
                    <h1 v-if="client.hajat == 'wanita'">{{client.nama_wanita}} &amp; {{client.nama_pria}}</h1>
                    <h1 v-if="client.hajat == 'pria'">{{client.nama_pria}} &amp; {{client.nama_wanita}}</h1>
                    <h2 v-if="config.countdown === 1">
                        {{ config.language === 1 ? moment(client.tgl_resepsi).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_resepsi).locale("id").format("dddd, DD MMMM YYYY") }}
                    </h2>
                    <h2 v-else>
                        {{ config.language === 1 ? moment(client.tgl_akad).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }}
                    </h2>

                    <p>
                        <br>
                        <a href="#fh5co-couple" class="btn btn-default btn-sm">Scroll to down</a>
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </header>
</template>

<script>
import moment from 'moment';
export default {
  props: ['client', 'config'],
  data() {
    return{
        moment: moment,
    }
  },
}
</script>