<template>
    <div id="fh5co-inv" class="fh5co-bg" style="background-image:url(../assets/images/image1.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <!-- <span>Our Special Events</span> -->
            <h2 style="font-family: 'Oleo Script', Sans-serif;">
              {{ config.language === 1 ? "Also Invite" : 'Turut Mengundang' }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="display-t">
            <div class="display-tc">
                <div class="col-md-6 col-sm-6 text-center">
                  <div class="event-wrap animate-box">
                    <h5 style="font-family: 'Fredoka One', cursive;">
                      {{ config.language === 1 ? "Bride's Family" : 'Keluarga Mempelai Wanita' }}
                    </h5>
                    <ul v-for="invite in alsoInvite" :key="invite.id" class="list-group">
                      <p v-if="invite.type === 'wanita'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 text-center">
                  <div class="event-wrap animate-box">
                    <h5 style="font-family: 'Fredoka One', cursive;">
                      {{ config.language === 1 ? "Groom's Family" : 'Keluarga Mempelai Pria' }}
                    </h5>
                    <ul v-for="invite in alsoInvite" :key="invite.id" class="list-group">
                      <p v-if="invite.type === 'pria'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: ['alsoInvite', 'config'],
  data() {
    return{
      
    }
  },
}
</script>