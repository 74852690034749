<template>
    
    <div id="fh5co-quotes" class="fh5co-bg" style="background-image:url(images/img_bg_3.jpg);">
		<div class="overlay2"></div>
		<div class="container">
            
			<div class="row">
                <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                    <h2 style="font-family: 'Oleo Script', Sans-serif;">
                        {{ config.language === 1 ? "Health Protocol" : 'Protokol Kesehatan' }}
                    </h2>
                    <h6 style="margin-top:20px;">
                        {{ config.language === 1 ? "Without reducing respect, due to the current situation amidst the COVID-19 pandemic, we apologize because the event will be held in accordance with government regulations and recommendations." : 'Tanpa mengurangi rasa hormat, dikarenakan situasi yang sedang terjadi ditengah pandemi COVID-19 ini kami memohon maaf karena acara akan diselenggarakan sesuai peraturan dan imbauan pemerintah.' }}
                    </h6>
                </div>
                <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                    <img style="width: 100%; max-width: 400px; height: auto;" src="assets/images/COVID-19.png" alt="">
                    
                </div>
			</div>
		</div>
	</div>
    
</template>

<script>
export default {
	props: ['config']
}
</script>