<template>
    <div id="fh5co-couple-story">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <h2 style="font-family: 'Oleo Script', Sans-serif;">
              {{ config.language === 1 ? "Wedding Gift" : 'Hadiah Pernikahan' }}
            </h2>
            <!-- <h6 style="margin-top:20px;">Tanpa mengurangi rasa hormat, untuk melengkapi kebahagiaan pengantin, Anda dapat memberikan tanda kasih dengan transfer ke rekening berikut:</h6> -->
            <h6 style="margin-top:20px;">
              {{ config.language === 1 ? "For those of you who wish to send wedding gifts, you can do so via:" : 'Bagi Bapak/Ibu/Saudara/i yang ingin mengirimkan hadiah pernikahan dapat melalui:' }}
            </h6>
            
          </div>
          <div class="display-t">
            <div class="display-tc">
              <div class="col-md-10 col-md-offset-1">
                <div class="col-md-6 col-sm-6 col-md-offset-3 text-center">
                  <div class="event-wrap animate-box">
                   <div class="card text-center">
                    <div class="card-body">
                      <div class="card-body" style="border: 2px solid #e79552; ">
                        <p class="card-text" v-if="client.bank == 'BCA'" style="padding-top: 10px;"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        <p class="card-text" v-if="client.bank == 'BRI'" style="padding-top: 10px;"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        <p class="card-text" v-if="client.bank == 'OVO'" style="padding-top: 10px;"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        <p class="card-text" v-if="client.bank == 'MANDIRI'" style="padding-top: 10px;"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        <p class="card-text" v-if="client.bank == 'GOPAY'" style="padding-top: 10px;"><img src="assets/images/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        <p class="card-text" v-if="client.bank == 'BNI'" style="padding-top: 10px;"><img src="assets/images/BNI.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        <input type="text" v-model="client.no_acc" id="myInput">

                        <!-- The button used to copy the text -->
                        <button data-target="#collapseExample" @click.prevent="myFunction" style="margin-bottom: 20px;">Copy</button>
                      </div>
                      
                      <div v-if="client.bank2 != null" class="card-body" style="border: 2px solid #e79552; margin-top: 20px;">
                        <p class="card-text" v-if="client.bank2 == 'BCA'" style="padding-top: 10px;"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                        <p class="card-text" v-if="client.bank2 == 'BRI'" style="padding-top: 10px;"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                        <p class="card-text" v-if="client.bank2 == 'OVO'" style="padding-top: 10px;"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                        <p class="card-text" v-if="client.bank2 == 'MANDIRI'" style="padding-top: 10px;"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                        <p class="card-text" v-if="client.bank2 == 'GOPAY'" style="padding-top: 10px;"><img src="assets/images/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                        <p class="card-text" v-if="client.bank2 == 'BNI'" style="padding-top: 10px;"><img src="assets/images/BNI.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                        <input type="text" v-model="client.no_acc2" id="myInput2">

                        <!-- The button used to copy the text -->
                        <button data-target="#collapseExample2" @click.prevent="myFunction2" style="margin-bottom: 20px;">Copy</button>
                      </div>
                      <div v-if="client.bank3 != null"  class="card-body" style="border: 2px solid #e79552; margin-top: 20px;">
                        <p class="card-text" v-if="client.bank3 == 'BCA'" style="padding-top: 10px;"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                        <p class="card-text" v-if="client.bank3 == 'BRI'" style="padding-top: 10px;"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                        <p class="card-text" v-if="client.bank3 == 'OVO'" style="padding-top: 10px;"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                        <p class="card-text" v-if="client.bank3 == 'MANDIRI'" style="padding-top: 10px;"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                        <p class="card-text" v-if="client.bank3 == 'GOPAY'" style="padding-top: 10px;"><img src="assets/images/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                        <p class="card-text" v-if="client.bank3 == 'BNI'" style="padding-top: 10px;"><img src="assets/images/BNI.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                        <input type="text" v-model="client.no_acc3" id="myInput3">

                        <!-- The button used to copy the text -->
                        <button data-target="#collapseExample3" @click.prevent="myFunction3" style="margin-bottom: 20px;">Copy</button>
                      </div>
                      <div class="card-body" v-if="client.bank4 != null"  style="border: 2px solid #e79552; margin-top: 20px;">
                        <p class="card-text" v-if="client.bank4 == 'BCA'" style="padding-top: 10px;"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                        <p class="card-text" v-if="client.bank4 == 'BRI'" style="padding-top: 10px;"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                        <p class="card-text" v-if="client.bank4 == 'OVO'" style="padding-top: 10px;"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                        <p class="card-text" v-if="client.bank4 == 'MANDIRI'" style="padding-top: 10px;"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                        <p class="card-text" v-if="client.bank4 == 'GOPAY'" style="padding-top: 10px;"><img src="assets/images/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                        <p class="card-text" v-if="client.bank4 == 'BNI'" style="padding-top: 10px;"><img src="assets/images/BNI.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                        <input type="text" v-model="client.no_acc4" id="myInput4">

                        <!-- The button used to copy the text -->
                        <button data-target="#collapseExample4" @click.prevent="myFunction4" style="margin-bottom: 20px;">Copy</button>
                      </div>

                      <a :href="'https://api.whatsapp.com/send?phone=' + client.wa_number + '&text= Hai, saya mau mengkonfirmasi sebelumnya saya sudah mengirimkan angpao atas nama .... Terima kasih'" target="_blank" type="submit" class="btn btn-default btn-block" >
                        {{ config.language === 1 ? "Confirmation" : 'Konfirmasi' }}
                      </a>
                      
                      <div v-if="client.gift_address !== null" class="card-body" style="border: 2px solid #e79552; margin-top: 15px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center" style="padding-top: 10px;"> 
                            <h4 style="font-family: 'Oleo Script', Sans-serif; color: #e79552;">
                              {{ config.language === 1 ? "Physical Gift Address" : 'Alamat Hadiah Fisik' }}
                            </h4>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3" style="padding: 15px;">
                            <p v-html="client.gift_address"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
	props: ['client', 'config'],
    data(){
        return{
            name: '',
            picked: '',
        }
    },
    methods: {
        
        myFunction() {
        /* Get the text field */
          var copyText = document.getElementById("myInput");
          const button = document.body.querySelector('[data-target="#collapseExample"]');

          /* Select the text field */
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */

          /* Copy the text inside the text field */
          navigator.clipboard.writeText(copyText.value);

          if(button.innerText.toLowerCase() === 'copy') {
              button.innerText = 'Copied';
          }
        },
        myFunction2() {
        /* Get the text field */
          var copyText = document.getElementById("myInput2");
          const button = document.body.querySelector('[data-target="#collapseExample2"]');

          /* Select the text field */
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */

          /* Copy the text inside the text field */
          navigator.clipboard.writeText(copyText.value);

          if(button.innerText.toLowerCase() === 'copy') {
              button.innerText = 'Copied';
          }
        },
        myFunction3() {
        /* Get the text field */
          var copyText = document.getElementById("myInput3");
          const button = document.body.querySelector('[data-target="#collapseExample3"]');

          /* Select the text field */
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */

          /* Copy the text inside the text field */
          navigator.clipboard.writeText(copyText.value);

          if(button.innerText.toLowerCase() === 'copy') {
              button.innerText = 'Copied';
          }
        },
        myFunction4() {
        /* Get the text field */
          var copyText = document.getElementById("myInput4");
          const button = document.body.querySelector('[data-target="#collapseExample4"]');

          /* Select the text field */
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */

          /* Copy the text inside the text field */
          navigator.clipboard.writeText(copyText.value);

          if(button.innerText.toLowerCase() === 'copy') {
              button.innerText = 'Copied';
          }
        },
    }
}
</script>
